import { json } from '@remix-run/cloudflare';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import * as Sentry from '@sentry/remix';
import { Toaster } from 'react-hot-toast';
import rdtStylesheet from 'remix-development-tools/index.css';
import { ExternalScripts } from 'remix-utils/external-scripts';

import { useToaster } from 'aslan';
import {
  CloudflareInsights,
  useTheLeapAnalytics,
  GoogleTagManagerDataLayer,
  GoogleTagManagerNoScript,
  HubSpotTracking } from
'analytics';
import { useDirection, useT } from 'i18n';
import { getToastMessage, getSession } from 'leap-auth';
import { logBoundaryErrors } from 'utils';

import ErrorLayout from './components/ErrorLayout';
import { ClientError } from './components/ErrorLayout/ClientError';
import { ServerError } from './components/ErrorLayout/ServerError';
import ExposeAppConfig from './components/ExposeAppConfig';
import { i18n } from './i18n.server';
import customStylesheetUrl from './styles/custom.css';
import tailwindStylesheetUrl from './styles/tailwind.css';

import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/cloudflare';
import type { ToasterProps } from 'aslan';

export const links: LinksFunction = () => [
{
  rel: 'preload',
  as: 'font',
  href: '/fonts/favorit-bold.woff2',
  type: 'font/woff2',
  crossOrigin: 'anonymous'
},
{
  rel: 'preload',
  as: 'font',
  href: '/fonts/favorit-regular.woff2',
  type: 'font/woff2',
  crossOrigin: 'anonymous'
},
{
  rel: 'preload',
  as: 'font',
  href: '/fonts/tobias-light.woff2',
  type: 'font/woff2',
  crossOrigin: 'anonymous'
},
{
  rel: 'apple-touch-icon',
  href: '/apple-touch-icon.png',
  type: 'image/png',
  sizes: '180x180'
},
{
  rel: 'mask-icon',
  href: '/safari-pinned-tab.svg',
  color: '#f0edee'
},
{
  rel: 'icon',
  href: '/favicon-16x16.png',
  sizes: '16x16',
  type: 'image/png'
},
{
  rel: 'icon',
  href: '/favicon-32x32.png',
  sizes: '32x32',
  type: 'image/png'
},
{ rel: 'stylesheet', href: tailwindStylesheetUrl },
{ rel: 'stylesheet', href: customStylesheetUrl },
...(process.env.NODE_ENV === 'development' ?
[{ rel: 'stylesheet', href: rdtStylesheet }] :
[])];


export async function loader(args: LoaderFunctionArgs) {
  const { env } = args.context;
  const { session, commitSession } = await getSession(args);

  const locale = await i18n.getLocale(args.request);
  const toastMessage = await getToastMessage(session);

  const data = {
    appConfig: {
      // * WARNING *: Do not expose any sensitive variables here since it is included in the bundles
      mixpanelToken: env.MIXPANEL_TOKEN,
      mixpanelDevEnvDistinctId: env.MIXPANEL_DEV_ENV_DISTINCT_ID,
      sentryDsn: env.SENTRY_DSN,
      cfBeacon: env.CF_BEACON,
      googleAnalyticsId: env.GTM_ID,
      hubSpotId: env.HUBSPOT_ID,
      hubSpotBusinessUnitId: env.HUBSPOT_BUSINESS_UNIT_ID,
      remixApp: env.REMIX_ENV
      // * END WARNING *
    },
    locale,
    toastMessage
  };
  return json(data, {
    headers: {
      'Set-Cookie': (await commitSession(session)) || ''
    }
  });
}

let AppExport = App;

if (process.env.NODE_ENV === 'development') {
  const { withDevTools } = require('remix-development-tools');
  AppExport = withDevTools(AppExport);
}

function App() {
  let { locale, appConfig, toastMessage } = useLoaderData<typeof loader>();
  const dir = useDirection();
  useTheLeapAnalytics({ appConfig }); // TODO: figure out anonymous tracking

  useToaster((toastMessage as ToasterProps));
  return (
    <html lang={locale} dir={dir} className="leading-normal h-full">
      <head>
        {/*
           Google Tag Manager Requirement #1
           This helps to ensure that your Tag Manager
           configuration is available and ready when the
           rest of the page loads.
          */}
        {appConfig.googleAnalyticsId &&
        <GoogleTagManagerDataLayer
          googleAnalyticsId={appConfig.googleAnalyticsId} />}


        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <ExposeAppConfig appConfig={appConfig} />
      </head>
      <body className="antialiased h-full bg-pink-900 text-brown-900">
        {appConfig.googleAnalyticsId &&
        <GoogleTagManagerNoScript id={appConfig.googleAnalyticsId} />}

        <a
          className="sr-only focus:not-sr-only focus:p-2 focus:m-2 focus:inline-block focus:top focus:absolute focus:bg-black focus:text-white focus:text-lg focus:font-bold focus:z-50"
          href="#main-content">

          Skip to content
        </a>
        <Toaster position="top-right" />
        <Outlet />
        <Toaster position="top-right" />
        <ScrollRestoration />
        <ExternalScripts />
        <Scripts />
        <CloudflareInsights cfBeacon={appConfig.cfBeacon} />
        <HubSpotTracking
          hubSpot={{
            id: appConfig.hubSpotId,
            businessUnitId: appConfig.hubSpotBusinessUnitId
          }} />


        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();
  const t = useT('translation', 'errorResponse.layout');
  logBoundaryErrors(error, Sentry);

  return (
    <html lang="en" className="leading-normal">
      <head>
        <title>{`${t('title')} | The Leap`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="antialiased h-full bg-gray-100 text-brown-900">
        <ErrorLayout>
          {isRouteErrorResponse(error) ?
          <ClientError status={error.status} /> :

          <ServerError />}

        </ErrorLayout>
        <Scripts />
      </body>
    </html>);

}

export default Sentry.withSentry(AppExport);