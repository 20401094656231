import { twMerge } from 'tailwind-merge';

import { useT } from 'i18n';

import ButtonLink from '../ButtonLink';
import { ExtendedTwirlSVG } from '../Icons/ExtendedTwirlSVG';

import type { PropsWithChildren } from 'react';

interface ErrorLayoutProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
}

export const ErrorLayout = ({ children, className }: ErrorLayoutProps) => {
  const t = useT('translation', 'errorResponse.layout');
  return (
    <div
      className={twMerge(
        'w-full min-h-full error-page-background-image text-center',
        className
      )}
      data-testid="error-layout"
    >
      <main className="flex flex-col space-y-10 items-center justify-center mx-auto w-full max-w-xl p-4">
        {/* Header */}
        <ExtendedTwirlSVG />
        {/* Error Response Custom Messaging */}
        {children}
        {/* Footer */}
        <ButtonLink
          color="yellow"
          to={'https://accounts.theleap.co/signup'}
          isRounded
          isFullWidth
          className="my-3 md:w-1/2 md:pr-2"
        >
          {t('goToSignupPage')}
        </ButtonLink>
      </main>
    </div>
  );
};
